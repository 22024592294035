<template>
  <div class="main-view main-view-no-title">
    <tenant-picker :tenants="tenantsData"></tenant-picker>
    <!-- <tenant-code-picker v-if="!tenant"></tenant-code-picker> -->
  </div>
</template>

<script>
import TenantPicker from '../components/tenants/TenantPicker.vue';

export default {
  name: 'Home',
  components: {
    TenantPicker,
  },
  props: [
    'tenants',
  ],
  data() {
    return {
      isLoading: true,
      isAuthenticated: false,
      tenantCode: '',
      tenantsData: [],
      tenant: localStorage.getItem('tenant'),
    };
  },
  async created() {
    if (this.$props.tenants) {
      this.tenantsData = this.$props.tenants;
    }
    const tenantFromRoute = this.$route.params.tenant || localStorage.getItem('tenant') || this.$store.state.tenant;
    const isAuthenticated = await this.$auth.isAuthenticated();
    if (!tenantFromRoute && !isAuthenticated) { // If no route and user not logged in
      this.$buefy.dialog.alert({
        title: 'Important',
        message: 'No tenant specified. Click Go to redirect to kineticsoftware.com',
        confirmText: 'Go',
        type: 'is-info',
        hasIcon: true,
        onConfirm: () => {
          window.location = 'https://www.kineticsoftware.com';
        },
      });
    } else if (!tenantFromRoute && isAuthenticated) {
      this.dataService = this.$dataService(tenantFromRoute);

      const tenants = (await this.dataService.tenantsList()).data;
      if (tenants.length === 1) {
        this.$router.push({ name: 'landing', params: { tenant: tenants[0].code } });
      } else {
        this.tenantsData = tenants;
      }
    } else if (tenantFromRoute) {
      this.$router.push({ name: 'landing', params: { tenant: tenantFromRoute } });
    }
  },
};
</script>

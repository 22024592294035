<template>
<div>
  <loader v-if="!tenants"></loader>
  <div>
    <div class="card tenant-row" v-for="(tenant, index) in tenants" v-bind:key="index">
      <header class="card-header">
        <p class="card-header-title">
          {{tenant.name}}
        </p>
        <router-link
          class="card-header-icon"
          aria-label="Use Tenant"
          :to="{ name: 'landing', params: { tenant: tenant.code } }">
          <b-icon icon="login-variant"></b-icon>
        </router-link>
      </header>
    </div>
  </div>
  <div v-if="IS_LOGGED_IN && !tenants" class="content has-text-grey has-text-centered">
    <p>
      <b-icon icon="emoticon-sad" size="is-large"></b-icon>
    </p>
    <p>You don't have access to any tenants.</p>
  </div>
</div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  name: 'TenantPicker',
  computed: {
    ...mapGetters([
      'PROFILE',
      'IS_LOGGED_IN',
    ]),
  },
  methods: {

  },
  props: {
    tenants: Array,
  },
};
</script>

<style lang="scss" scoped>
  .tenant-row {
    @media screen and (min-width: 769px) {
      width: 256px;
    }
  }
</style>
